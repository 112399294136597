import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { configure } from "mobx";
import { Helmet } from "react-helmet";
// @ts-expect-error - Vite plugin
import { hideSplashScreen } from "vite-plugin-splash-screen/runtime";
import { HowlerProvider } from "./context/howler";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.css";
import { Layout } from "./components/Layout";
import NotFound from "./views/NotFound";
import Home from "./views/Home";
import { Header } from "./components/Header";
import { AppToolbar } from "./components/AppToolbar";
import { SignupModal } from "./components/SignupModal";
import { Content } from "./components/layout/Content";
import { PageLoading } from "./components/layout/PageLoading";
import { auth } from "./core/firebase";
import { useStores } from "./hooks/useStores";
import { Gdpr } from "./components/Gdpr";

const English = lazy(() => import("./views/English"));
const ListPodcastView = lazy(() => import("./views/ListPodcastView"));
const TopListPodcastView = lazy(() => import("./views/TopListPodcastView"));
const UserAccountView = lazy(() => import("./views/UserAccountView"));
const FindView = lazy(() => import("./views/FindView"));
const PolicyView = lazy(() => import("./views/PolicyView"));
const AboutView = lazy(() => import("./views/AboutView"));
const SubjectListView = lazy(() => import("./views/SubjectListView"));
const SubjectView = lazy(() => import("./views/SubjectView"));
const PodcastView = lazy(() => import("./views/PodcastView"));
const EpisodeView = lazy(() => import("./views/EpisodeView"));
const PodcastSeasonView = lazy(() => import("./views/PodcastSeasonView"));
const AuthorView = lazy(() => import("./views/AuthorView"));
const CollectionView = lazy(() => import("./views/CollectionView"));
const CollectionList = lazy(() => import("./views/CollectionList"));
const Categories = lazy(() => import("./views/Categories"));
const Category = lazy(() => import("./views/Category"));

configure({
    enforceActions: "never",
});

export const App = () => {
    const [user, loading] = useAuthState(auth);
    const { userStore, ratingStore } = useStores();

    ratingStore.loadMyRatings().catch(console.error);

    useEffect(() => {
        userStore.setAuthing(loading);
    }, [loading, userStore]);

    useEffect(() => {
        hideSplashScreen().catch(console.error);
    }, []);

    useEffect(() => {
        if (user) {
            const { displayName, photoURL, email, uid, providerId } = user;
            user.getIdToken()
                .then((token) => {
                    userStore
                        .setUser({
                            displayName,
                            photoURL,
                            email,
                            uid,
                            provider: providerId,
                            token,
                        })
                        .catch(console.error);
                    userStore.setLoggedIn(true);
                })
                .catch(console.error);
        }
    }, [user, userStore]);

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "sv-se",
                }}>
                <title>Hitta Podd - Populära Podcasts - HittaPodd.se</title>
                <meta
                    name="description"
                    content="Hitta podcasts att lyssna på. För oss som älskar poddar. HittaPodd.se"
                />
            </Helmet>

            <HowlerProvider>
                <BrowserRouter>
                    <Content>
                        <Header />
                        <Suspense fallback={<PageLoading />}>
                            <Routes>
                                <Route path={"/"} element={<Layout />}>
                                    <Route index element={<Home />} />

                                    <Route path={"podd"}>
                                        <Route path={":slug"}>
                                            <Route path={"season/:seasonSlug"} element={<PodcastSeasonView />} />
                                            <Route path={":episode"} element={<EpisodeView />} />
                                            <Route index element={<PodcastView />} />
                                        </Route>
                                    </Route>

                                    <Route path={"kategori"}>
                                        <Route path={":slug"} element={<Category />} />
                                        <Route index element={<Categories />} />
                                    </Route>

                                    <Route path={"upphovsman"}>
                                        <Route path={":slug"} element={<AuthorView />} />
                                    </Route>

                                    <Route path={"samling"}>
                                        <Route path={":slug"} element={<CollectionView />} />
                                        <Route index element={<CollectionList />} />
                                    </Route>

                                    <Route path={"fokus"}>
                                        <Route path={":slug"} element={<SubjectView />} />
                                        <Route index element={<SubjectListView />} />
                                    </Route>

                                    <Route path={"om"} element={<AboutView />} />
                                    <Route path={"policy"} element={<PolicyView />} />
                                    <Route path={"hitta"} element={<FindView />} />
                                    <Route path={"en"} element={<English />} />
                                    <Route path={"poddar"} element={<ListPodcastView />} />
                                    <Route path={"topplista"} element={<TopListPodcastView />} />
                                    <Route path={"topplista/:date"} element={<TopListPodcastView />} />

                                    <Route path={"account"} element={<UserAccountView />} />

                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            </Routes>
                        </Suspense>
                        <AppToolbar />
                    </Content>
                    <SignupModal />
                    <Gdpr />
                </BrowserRouter>
            </HowlerProvider>
        </>
    );
};
