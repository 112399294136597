import { Outlet } from "react-router-dom";
import { Adsense } from "@ctrl/react-adsense";
import { Player } from "./Player";
import { PlaylistModal } from "./PlaylistModal";
import styles from "./styles/Layout.module.css";

export const Layout = () => {
    return (
        <div className={"main " + styles.main}>
            <div className="container">
                <div className={styles.adsense}>
                    <Adsense client={"ca-pub-9280562235028230"} slot={"8516405729"} responsive={"true"} />
                </div>
                <Outlet />
            </div>
            <Player key="7" />
            <PlaylistModal />
        </div>
    );
};
